// export const API_BASE_URL = "http://192.168.29.33:6060";
export const API_BASE_URL = "https://api.dw-its.com";
// export const MEDIA_URL = "https://zan-rides-assets.s3.af-south-1.amazonaws.com";

export const ApiEndpoints = {
  HOME: {
    list: `${API_BASE_URL}/api/admin/home`,
  },
  CONTACTUS: {
    list: `${API_BASE_URL}/api/admin/contact-us`,
  },
  CAREER: {
    list: `${API_BASE_URL}/api/admin/career`,
  },
  CAREER_DETAILS: {
    list: (id) => `${API_BASE_URL}/api/admin/career/${id}`,
  },
  BLOGS: {
    list: `${API_BASE_URL}/api/admin/blog`,
  },
  BLOGS_DETAILS: {
    list: (id) => `${API_BASE_URL}/api/admin/blog/${id}`,
  },
  CLIENT_CATEGORY: {
    list: `${API_BASE_URL}/api/admin/client-catagory`,
  },
  SUCCESS_STORIES: {
    list: `${API_BASE_URL}/api/admin/success-stories`,
  },
  IT_SOLUTIONS: {
    list: `${API_BASE_URL}/api/admin/it-solution`,
  },
  SUB_IT_SOLUTIONS: {
    list: (id) => `${API_BASE_URL}/api/admin/sub-it-solution/${id}`,
  },
  SUB_IT_SOLUTIONS_LIST: {
    list: (id) => `${API_BASE_URL}/api/admin/sub-it-solution-list/${id}`,
  },
  // IT-Solution Overview
  IT_SOLUTION_OVERVIEW: {
    list: (id) => `${API_BASE_URL}/api/admin/it-solution-over-view/${id}`,
  },
  IT_SOLUTION_SUB_OVERVIEW: {
    list: (id) => `${API_BASE_URL}/api/admin/it-solution-sub-over-view/${id}`,
  },
  IT_SOLUTION_OVERVIEW_HOME: {
    list: (id) => `${API_BASE_URL}/api/admin/it-solution-home-over-view/${id}`,
  },
  IT_SOLUTION_OVERVIEW_FEATURES_TITLE: {
    list: (id) =>
      `${API_BASE_URL}/api/admin/it-solution-over-view-feature-title/${id}`,
  },
  IT_SOLUTION_OVERVIEW_FEATURES: {
    list: (id) =>
      `${API_BASE_URL}/api/admin/it-solution-over-view-feature/${id}`,
  },
  IT_SOLUTION_OVERVIEW_PLAN: {
    list: (id) => `${API_BASE_URL}/api/admin/it-solution-over-view-plan/${id}`,
  },

  // IT-Solution Features
  IT_SOLUTION_FEATURES: {
    list: (id) => `${API_BASE_URL}/api/admin/it-solution-feature/${id}`,
  },
  IT_SOLUTION_SUB_FEATURES: {
    list: (id) => `${API_BASE_URL}/api/admin/it-solution-sub-feature/${id}`,
  },
  IT_SOLUTION_FEATURES_TITLE: {
    list: (id) => `${API_BASE_URL}/api/admin/it-solution-feature-title/${id}`,
  },
  SUB_ADDITIONAL_FEATURES: {
    list: (id) =>
      `${API_BASE_URL}/api/admin/it-solution-additional-feature/${id}`,
  },
  SUB_ADDITIONAL_FEATURES_LIST: {
    list: (id) =>
      `${API_BASE_URL}/api/admin/it-solution-additional-feature-list/${id}`,
  },

  // IT-Solution Resources
  IT_SOLUTION_RESOURCES: {
    list: (id) => `${API_BASE_URL}/api/admin/it-solution-resources/${id}`,
  },
  IT_SOLUTION_RESOURCES_DETAILS: {
    list: (id) => `${API_BASE_URL}/api/admin/it-solution-resources-list/${id}`,
  },
  //forms
  GET_IN_TOUCH: {
    create: `${API_BASE_URL}/api/get-in-touch`,
  },
  GET_SUPPORT_WITH_US: {
    create: `${API_BASE_URL}/api/get-support-with-us`,
  },
  PERSONALIZED_WEBDEMO: {
    create: `${API_BASE_URL}/api/personalized-web-demo`,
  },
  ADD_PERSONALIZED_QUOTE: {
    create: `${API_BASE_URL}/api/personalized-quote`,
  },
  APPLICATION_FORM: {
    create: `${API_BASE_URL}/api/application-form`,
  },
};
