export default {
  MuiSelect: {
    styleOverrides: {
      select: {
        // minWidth: '6rem !important',
        "&.MuiTablePagination-select": {
          minWidth: "1.5rem !important",
        },
      },
    },
  },
};
