// ** Next Import
import { NavLink } from "react-router-dom";

// ** MUI Imports
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import { styled, useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

// ** Icons
import Close from "mdi-material-ui/Close";
import CircleOutline from "mdi-material-ui/CircleOutline";
import RecordCircleOutline from "mdi-material-ui/RecordCircleOutline";

// ** Configs
import themeConfig from "src/configs/themeConfig";
import Logo from "src/@core/components/logo";
import logo from "../../../../../assets/images/logo.svg";

// ** Styled Components
const MenuHeaderWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  // justifyContent: 'space-between',
  justifyContent: "center",
  paddingRight: theme.spacing(4.5),
  transition: "padding .25s ease-in-out",
  minHeight: theme.mixins.toolbar.minHeight,
}));

const HeaderTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  lineHeight: "normal",
  textTransform: "uppercase",
  color: theme.palette.text.primary,
  transition: "opacity .25s ease-in-out, margin .25s ease-in-out",
}));

const StyledLink = styled("div")({
  display: "flex",
  alignItems: "center",
  textDecoration: "none",
});

const VerticalNavHeader = (props) => {
  // ** Props
  const {
    hidden,
    navHover,
    settings,
    saveSettings,
    collapsedNavWidth,
    toggleNavVisibility,
    navigationBorderWidth,
    menuLockedIcon: userMenuLockedIcon,
    menuUnlockedIcon: userMenuUnlockedIcon,
    verticalNavMenuBranding: userVerticalNavMenuBranding,
  } = props;

  // ** Hooks & Vars
  const theme = useTheme();
  const { navCollapsed } = settings;
  const menuCollapsedStyles =
    navCollapsed && !navHover ? { opacity: 0 } : { opacity: 1 };

  const menuHeaderPaddingLeft = () => {
    if (navCollapsed && !navHover) {
      if (userVerticalNavMenuBranding) {
        return 0;
      } else {
        return (collapsedNavWidth - navigationBorderWidth - 30) / 8;
      }
    } else {
      return 6;
    }
  };

  const MenuLockedIcon = () =>
    userMenuLockedIcon || (
      <RecordCircleOutline
        sx={{
          fontSize: "1.25rem",
          pointerEvents: "none",
          ...menuCollapsedStyles,
          transition: "opacity .25s ease-in-out",
        }}
      />
    );

  const MenuUnlockedIcon = () =>
    userMenuUnlockedIcon || (
      <CircleOutline
        sx={{
          fontSize: "1.25rem",
          pointerEvents: "none",
          ...menuCollapsedStyles,
          transition: "opacity .25s ease-in-out",
        }}
      />
    );

  return (
    <MenuHeaderWrapper
      className="nav-header"
      sx={{ pl: menuHeaderPaddingLeft(), marginTop: "20px" }}
    >
      {userVerticalNavMenuBranding ? (
        userVerticalNavMenuBranding(props)
      ) : (
        <NavLink to="/" style={{ textDecoration: "none" }}>
          <StyledLink>
            {/* TODO: Add custom logo */}
            {/* <Logo /> */}
            <img src={logo} />
          </StyledLink>
        </NavLink>
      )}
    </MenuHeaderWrapper>
  );
};

export default VerticalNavHeader;
