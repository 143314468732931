import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import ListItemButton from '@mui/material/ListItemButton'
import LogoutIcon from '../../../@core/components/icons/LogoutIcon'
import themeConfig from '../../../configs/themeConfig'
import Translations from '../Translations'
import { useAuth } from '../../../hooks/useAuth'
import { Button, ListItem, ListItemIcon } from '@mui/material'
import UserIcon from '../UserIcon'
import TranslateIcon from '@mui/icons-material/Translate'
import { Link, useNavigate } from 'react-router-dom'
// ** Styled Components
const MenuNavLink = styled(ListItemButton)(({ theme }) => ({
  width: '100%',
  borderRadius: 100,
  borderTopRightRadius: 100,
  borderBottomRightRadius: 100,
  color: theme.palette.text.primary,
  transition: 'padding-left .25s ease-in-out',
  backgroundColor: theme.palette.secondary.main,
  '&:hover': {
    boxShadow: theme.shadows[3],
    backgroundColor: theme.palette.secondary.dark
  },
  '& .MuiTypography-root, & .MuiListItemIcon-root': {
    color: `${theme.palette.common.white} !important`
  }
}))
const MenuNavLinkLang = styled(ListItemButton)(({ theme }) => ({
  width: '100%',
  borderRadius: 100,
  borderTopRightRadius: 100,
  borderBottomRightRadius: 100,
  transition: 'padding-left .25s ease-in-out',
  border: '1px solid',
  borderColor: 'theme.palette.neutral.70'
}))
const MenuItemTextMetaWrapper = styled(Box)({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  transition: 'opacity .25s ease-in-out',
  ...(themeConfig.menuTextTruncate && { overflow: 'hidden' })
})

function AfterVerticalNavMenuContent(props) {
  const navigate = useNavigate()
  const ChangeLanguage = e => {
    navigate('/changeLanguage')
  }
  // ** Hooks
  const auth = useAuth()

  const { settings, navHover, collapsedNavWidth, navigationBorderWidth } = props
  const { navCollapsed } = settings

  return (
    // <Box
    //   className='nav-items'
    //   sx={{
    //     transition: 'padding .25s ease',
    //     '& > :first-of-type': { mt: '0' },
    //     pr: !navCollapsed || (navCollapsed && navHover) ? 4.5 : 1.25,
    //     pl: !navCollapsed || (navCollapsed && navHover) ? 4.5 : 1.25,
    //     pt: !navCollapsed || (navCollapsed && navHover) ? 4.5 : 1.25,
    //     pb: !navCollapsed || (navCollapsed && navHover) ? 4.5 : 1.25
    //   }}
    // >
    <>
      {/* <Box display='flex' flexDirection='column' alignItems='center' gap={3} pr='113px'>
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
        }}
        >
          <Button as={Link} to='http://d2u7u7obksrdp.cloudfront.net/signup' sx={{ textDecoration: 'none', color: '#262626' }}>
            Sign Up
          </Button>
        </Box>
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
        }}
        >
          <Button variant='contained' as={Link} to='http://d2u7u7obksrdp.cloudfront.net/login' sx={{ textDecoration: 'none', }}>
            Login
          </Button>
        </Box>
      </Box> */}
    </>
  )
}

export default AfterVerticalNavMenuContent
