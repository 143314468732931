import { TabContext, TabPanel } from "@mui/lab";
import {
  Box,
  Grid,
  Typography,
  Divider,
  Tooltip,
  Tabs,
  Tab,
} from "@mui/material";

import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { axiosInstance } from "src/network/adapter";
import { ApiEndpoints } from "src/network/endpoints";
import { toastError } from "src/utils/utils";

const ITSolutionDropDown = () => {
  const [itSolutions, setItSolutions] = useState([]);
  const [subItSolutions, setSubItSolutions] = useState([]);
  const [subItSolutionsLists, setSubItSolutionsLists] = useState([]); // Store multiple sub-lists dynamically
  const navigate = useNavigate();
  const [activeITSolutionId, setActiveITSolutionId] = useState(null);

  const fetchITSoltionsData = () => {
    axiosInstance
      .get(ApiEndpoints.IT_SOLUTIONS.list)
      .then((response) => {
        const responseData = response.data.data;
        setItSolutions(response.data.data);

        if (responseData.length > 0) {
          fetchSubITSoltionsData(responseData[0].id); // Get the id of the first IT solution
          setActiveITSolutionId(responseData[0].id); // Get the id of the first IT solution
        }
      })
      .catch((error) => {
        toastError(error);
      });
  };

  const fetchSubITSoltionsData = (id) => {
    axiosInstance
      .get(ApiEndpoints.SUB_IT_SOLUTIONS.list(id))
      .then((response) => {
        setSubItSolutions(response.data.data);
        const responseData = response.data.data;
        if (responseData) {
          responseData.forEach((item, index) => {
            fetchSubITSoltionsList(item.id, index);
          });
        }
      })
      .catch((error) => {
        toastError(error);
      });
  };

  const fetchSubITSoltionsList = (id, index) => {
    axiosInstance
      .get(ApiEndpoints.SUB_IT_SOLUTIONS_LIST.list(id))
      .then((response) => {
        setSubItSolutionsLists((prevState) => {
          const newState = [...prevState];
          newState[index] = response.data.data;
          return newState;
        });
      })
      .catch((error) => {
        toastError(error);
      });
  };

  useEffect(() => {
    fetchITSoltionsData();
  }, []);

  const handleITSolutionClick = (id, event) => {
    event.stopPropagation();
    fetchSubITSoltionsData(id);
    setActiveITSolutionId(id);
  };

  const handleSubItSolutionClick = (index, subITSolutionId) => {
    if (subITSolutionId) {
      localStorage.setItem("ITSolutionId", subITSolutionId);
    }
    navigate(`/it-solution-overview/${subITSolutionId}`);
  };

  return (
    <>
      <Box
        sx={{
          width: "1280px",
        }}
      >
        <Grid container>
          <Grid item xs={4}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 5,
                py: 5,
              }}
            >
              <Typography
                sx={{
                  fontSize: { lg: "24px" },
                  lineHeight: { lg: "32px" },
                  fontWeight: 600,
                  color: "#602DE1",
                  pl: 3,
                }}
              >
                IT Solutions
              </Typography>
              <Box
                sx={{
                  pl: 5,
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                }}
              >
                {itSolutions?.map((item, index) => (
                  <Box
                    onClick={(event) => handleITSolutionClick(item.id, event)}
                    key={index}
                  >
                    <Typography
                      sx={{
                        fontSize: { lg: "16px" },
                        lineHeight: { lg: "24px" },
                        fontWeight: 600,
                        textWrap: "wrap",
                        "&:hover": {
                          color: "#ffff",
                          bgcolor: "#602DE1",
                        },
                        color:
                          activeITSolutionId === item.id ? "#ffff" : "#101828",
                        bgcolor:
                          activeITSolutionId === item.id ? "#602DE1" : "",
                        borderRadius: "10px",
                        cursor: "pointer",
                        px: 2,
                        py: 2,
                      }}
                    >
                      {item.title}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Box>
          </Grid>
          {/* <Grid item container xs={8} columnSpacing={10} sx={{ px: 10, py: 5 }}>
            {subItSolutions?.map((item, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Box sx={{ mb: 3 }}>
                  <Typography
                    sx={{
                      fontSize: { lg: "16px" },
                      lineHeight: { lg: "24px" },
                      fontWeight: 600,
                      color: "#602DE1",
                      mb: 1,

                      wordBreak: "break-word",
                      whiteSpace: "normal",
                    }}
                  >
                    {item.title}
                  </Typography>

                  <Typography
                    sx={{
                      fontSize: { lg: "14px" },
                      lineHeight: { lg: "20px" },
                      fontWeight: 400,
                      color: "#667085",
                      mb: 2,
                      textWrap: "wrap",
                    }}
                  >
                    <div
                      style={{
                        wordBreak: "break-word",
                        whiteSpace: "normal",
                      }}
                      dangerouslySetInnerHTML={{
                        __html: `<style>p { margin: 0; }</style>${item.description}`,
                      }}
                    />
                  </Typography>
                </Box>
                <Box sx={{ mb: 5 }}>
                  <Divider sx={{ width: "50%", borderWidth: "1px" }} />
                </Box>
                {subItSolutionsLists[index]?.map((subItem, subIndex) => (
                  <Box
                    key={subIndex}
                    onClick={() =>
                      handleSubItSolutionClick(subIndex, subItem.id)
                    }
                    sx={{ mb: 10 }}
                  >
                    <Typography
                      sx={{
                        fontSize: { lg: "16px" },
                        lineHeight: { lg: "24px" },
                        fontWeight: 500,
                        color: "#101828",
                        textWrap: "wrap",
                        "&:hover": {
                          color: "#602DE1",
                          cursor: "pointer",
                        },
                        mb: 1,
                      }}
                    >
                      {subItem.title}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: { lg: "14px" },
                        lineHeight: { lg: "20px" },
                        fontWeight: 400,
                        color: "#667085",
                        textWrap: "wrap",
                      }}
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html: `<style>p { margin: 0; }</style>${subItem.description}`,
                        }}
                      />
                    </Typography>
                  </Box>
                ))}
              </Grid>
            ))}
          </Grid> */}
          <Grid
            item
            xs={8}
            columnSpacing={10}
            sx={{
              px: 10,
              py: 5,
              display: "flex",
              flexWrap: "wrap",
              alignItems: "flex-start",
              gap: 6,
            }}
          >
            {subItSolutions?.map((item, index) => (
              <Box
                key={index}
                sx={{
                  width: "30%",
                }}
              >
                <Box sx={{ mb: 3 }}>
                  <Typography
                    sx={{
                      fontSize: { lg: "16px" },
                      lineHeight: { lg: "24px" },
                      fontWeight: 600,
                      color: "#602DE1",
                      mb: 1,
                      wordBreak: "break-word",
                      whiteSpace: "break-spaces",
                    }}
                  >
                    {item.title}
                  </Typography>

                  <Typography
                    sx={{
                      fontSize: { lg: "14px" },
                      lineHeight: { lg: "20px" },
                      fontWeight: 400,
                      color: "#667085",
                      mb: 2,
                    }}
                  >
                    <div
                      style={{
                        textWrap: "wrap",
                        whiteSpace: "break-spaces",
                      }}
                      dangerouslySetInnerHTML={{
                        __html: `<style>p { margin: 0; }</style>${item.description}`,
                      }}
                    />
                  </Typography>
                </Box>
                <Box
                //  sx={{ mb: 5 }}
                >
                  <Divider sx={{ width: "50%", borderWidth: "1px" }} />
                </Box>
                {subItSolutionsLists[index]?.map((subItem, subIndex) => (
                  <Box
                    key={subIndex}
                    onClick={() =>
                      handleSubItSolutionClick(subIndex, subItem.id)
                    }
                    sx={{ mb: 10 }}
                  >
                    <Typography
                      sx={{
                        fontSize: { lg: "16px" },
                        lineHeight: { lg: "24px" },
                        fontWeight: 500,
                        color: "#101828",
                        textWrap: "wrap",
                        "&:hover": {
                          color: "#602DE1",
                          cursor: "pointer",
                        },
                        mb: 1,
                      }}
                    >
                      {subItem.title}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: { lg: "14px" },
                        lineHeight: { lg: "20px" },
                        fontWeight: 400,
                        color: "#667085",
                      }}
                    >
                      <div
                        style={{ textWrap: "wrap", whiteSpace: "break-spaces" }}
                        dangerouslySetInnerHTML={{
                          __html: `<style>p { margin: 0; }</style>${subItem.description}`,
                        }}
                      />
                    </Typography>
                  </Box>
                ))}
              </Box>
            ))}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default ITSolutionDropDown;
