import {
  Box,
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { axiosInstance } from "src/network/adapter";
import { ApiEndpoints } from "src/network/endpoints";
import { toastError } from "src/utils/utils";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import FallbackSpinner from "src/@core/components/spinner";

const VerticleITSolutionDropDown = ({ openNavBar, onClose }) => {
  const [itSolutions, setItSolutions] = useState([]);
  const [subItSolutions, setSubItSolutions] = useState([]);
  const [subItSolutionsLists, setSubItSolutionsLists] = useState([]); // Store multiple sub-lists dynamically

  const navigate = useNavigate();

  const fetchITSoltionsData = () => {
    axiosInstance
      .get(ApiEndpoints.IT_SOLUTIONS.list)
      .then((response) => {
        const responseData = response.data.data;
        setItSolutions(response.data.data);

        // if (responseData.length > 0) {
        //   fetchSubITSoltionsData(responseData[0].id); // Get the id of the first IT solution
        // }
        if (responseData.length > 0) {
          // Fetch sub IT solutions data for each main IT solution
          responseData.forEach((solution, index) => {
            fetchSubITSoltionsData(solution.id, index);
          });
        }
      })
      .catch((error) => {
        toastError(error);
      });
  };

  const fetchSubITSoltionsData = (id, solutionIndex) => {
    axiosInstance
      .get(ApiEndpoints.SUB_IT_SOLUTIONS.list(id))
      .then((response) => {
        const responseData = response.data.data;
        // setSubItSolutions(response.data.data);
        setSubItSolutions((prevState) => {
          const newState = [...prevState];
          newState[solutionIndex] = responseData; // Store the fetched sub-IT solutions for this main solution
          return newState;
        });
        if (responseData) {
          responseData.forEach((item, subIndex) => {
            fetchSubITSoltionsList(item.id, solutionIndex, subIndex);
          });
        }
      })
      .catch((error) => {
        toastError(error);
      });
  };

  const fetchSubITSoltionsList = (id, solutionIndex, subIndex) => {
    axiosInstance
      .get(ApiEndpoints.SUB_IT_SOLUTIONS_LIST.list(id))
      .then((response) => {
        // setSubItSolutionsLists((prevState) => {
        //   const newState = [...prevState];
        //   newState[index] = response.data.data; // Store each sub-list at its respective index
        //   return newState;
        // });
        setSubItSolutionsLists((prevState) => {
          const newState = [...prevState];
          if (!newState[solutionIndex]) {
            newState[solutionIndex] = []; // Ensure an array exists for this solution's sub-lists
          }
          newState[solutionIndex][subIndex] = response.data.data; // Store each sub-list at its respective index
          return newState;
        });
      })
      .catch((error) => {
        toastError(error);
      });
  };

  // On component mount, fetch the main IT solutions and sub IT solutions for default ID 13
  useEffect(() => {
    fetchITSoltionsData();
  }, []);

  // Reusable function to handle clicks on sub IT solutions and navigate to the detail page
  const handleSubItSolutionClick = (index, subITSolutionId) => {
    if (subITSolutionId) {
      localStorage.setItem("ITSolutionId", subITSolutionId);
    }
    navigate(`/it-solution-overview/${subITSolutionId}`);
    onClose();
  };
  const handleClick = () => {
    onClose();
    openNavBar();
  };

  return (
    <>
      <Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 4,
            p: 3,
            cursor: "pointer",
          }}
          onClick={handleClick}
        >
          <Box
            sx={{
              bgcolor: "#E9E8EE",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "35px",
              width: "35px",
              borderRadius: "50px",
              pl: 1,
            }}
          >
            <ArrowBackIosIcon
              sx={{
                fontSize: "20px",
                color: "#602DE1",
              }}
            />
          </Box>
          <Typography
            sx={{
              fontSize: { xs: "20px", sm: "26px" },
              lineHeight: { xs: "24px", sm: "31px" },
              color: "#000000",
              fontWeight: 500,
            }}
          >
            Back
          </Typography>
        </Box>

        {itSolutions?.map((solution, index) => (
          <Accordion
            key={index}
            sx={{
              width: "100%",
              boxShadow: "none",
              "&.Mui-expanded": {
                boxShadow: "none",
                pb: 0,
                mt: 0,
              },
              py: 2,
            }}
            // onChange={(event, isExpanded) => {
            //   event.stopPropagation();
            //   if (isExpanded) {
            //     fetchSubITSoltionsData(solution.id);
            //   }
            // }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index}-content`}
              id={`panel${index}-header`}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%", // Make it responsive to container width
              }}
            >
              <Typography
                sx={{
                  fontSize: { lg: "16px" },
                  lineHeight: { lg: "24px" },
                  fontWeight: 600,
                  color: "#101828",
                  wordBreak: "break-word",
                  whiteSpace: "normal",
                  overflowWrap: "break-word",
                }}
              >
                {solution.title}
              </Typography>
            </AccordionSummary>

            <AccordionDetails>
              {/* Display sub IT solutions for the selected IT solution */}
              {subItSolutions[index]?.map((item, subIndex) => (
                <Box key={subIndex}>
                  <Box sx={{ mb: 3 }}>
                    <Typography
                      sx={{
                        fontSize: { lg: "16px" },
                        lineHeight: { lg: "24px" },
                        fontWeight: 600,
                        color: "#602DE1",
                        mb: 1,
                        wordBreak: "break-word",
                        whiteSpace: "normal",
                        overflowWrap: "break-word",
                      }}
                    >
                      {item.title}
                    </Typography>

                    <Typography
                      sx={{
                        fontSize: { lg: "14px" },
                        lineHeight: { lg: "20px" },
                        fontWeight: 400,
                        color: "#667085",
                        mb: 2,
                      }}
                    >
                      <div
                        style={{
                          wordBreak: "break-word",
                          whiteSpace: "normal",
                          overflowWrap: "break-word",
                        }}
                        dangerouslySetInnerHTML={{
                          __html: `<style>p { margin: 0; }</style>${item.description}`,
                        }}
                      />
                    </Typography>
                  </Box>

                  <Box sx={{ mb: 5 }}>
                    <Divider sx={{ width: "50%", borderWidth: "1px" }} />
                  </Box>

                  {subItSolutionsLists[index]?.[subIndex]?.map(
                    (subItem, subSubIndex) => (
                      <Box
                        key={subSubIndex}
                        onClick={() =>
                          handleSubItSolutionClick(subSubIndex, subItem.id)
                        }
                        sx={{ mb: 5 }}
                      >
                        <Typography
                          sx={{
                            fontSize: { lg: "16px" },
                            lineHeight: { lg: "24px" },
                            fontWeight: 500,
                            color: "#101828",
                            "&:hover": {
                              color: "#602DE1",
                              cursor: "pointer",
                            },
                            mb: 1,
                            wordBreak: "break-word",
                            whiteSpace: "normal",
                            overflowWrap: "break-word",
                          }}
                        >
                          {subItem.title}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: { lg: "14px" },
                            lineHeight: { lg: "20px" },
                            fontWeight: 400,
                            color: "#667085",
                          }}
                        >
                          <div
                            style={{
                              wordBreak: "break-word",
                              whiteSpace: "normal",
                              overflowWrap: "break-word",
                            }}
                            dangerouslySetInnerHTML={{
                              __html: `<style>p { margin: 0; }</style>${subItem.description}`,
                            }}
                          />
                        </Typography>
                      </Box>
                    )
                  )}
                </Box>
              ))}
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </>
  );
};

export default VerticleITSolutionDropDown;
