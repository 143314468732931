// ** MUI Imports
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";

// ** Icons Imports
import MenuIcon from "mdi-material-ui/Menu";

// ** Components
import Autocomplete from "src/layouts/components/Autocomplete";
import ModeToggler from "src/@core/layouts/components/shared-components/ModeToggler";
import UserDropdown from "src/@core/layouts/components/shared-components/UserDropdown";
import LanguageDropdown from "src/@core/layouts/components/shared-components/LanguageDropdown";
import NotificationDropdown from "src/@core/layouts/components/shared-components/NotificationDropdown";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import Logo from "src/@core/components/logo";
import logo from "../../../assets/images/logoSmall.svg";

const AppBarContent = (props) => {
  // ** Props
  const { hidden, settings, saveSettings, toggleNavVisibility } = props;
  const itSolutionID = localStorage.getItem("ITSolutionId");
  return (
    <Box
      className="actions-left"
      sx={{ display: "flex", alignItems: "center", width: "100%" }}
    >
      {hidden ? (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            py: 5,
          }}
        >
          <IconButton color="inherit" onClick={toggleNavVisibility}>
            <MenuIcon />
          </IconButton>
          <Box mt={"10px"}>
            <Link to="/" sx={{ textDecoration: "none" }}>
              {/* <Logo /> */}
              <img src={logo} />
            </Link>
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            gap={3}
            justifyContent={"flex-end"}
          >
            <Button
              variant="contained"
              // size="small"
              as={Link}
              to={`/it-solution-supportUs/${itSolutionID}`}
              sx={{
                textDecoration: "none",
                p: { xs: "3px 14px ", sm: "6px 24px " },
                borderRadius: { xs: "10px", sm: "16px" },
              }}
            >
              Get Support
            </Button>
          </Box>
        </Box>
      ) : null}

      {/* {/ <Autocomplete hidden={hidden} settings={settings} /> /} */}
    </Box>
  );
};

export default AppBarContent;
