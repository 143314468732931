// ** React Imports
import { Fragment, useEffect } from "react";

// ** Next Imports
import { Link, useLocation, useSearchParams } from "react-router-dom";

// ** MUI Imports
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import List from "@mui/material/List";
import { styled } from "@mui/material/styles";
import ListItemIcon from "@mui/material/ListItemIcon";
import MuiListItem from "@mui/material/ListItem";

// ** Third Party Imports
import clsx from "clsx";

// ** Theme Config Import
import themeConfig from "src/configs/themeConfig";

// ** Custom Components Imports
import UserIcon from "src/layouts/components/UserIcon";
import Translations from "src/layouts/components/Translations";
import CanViewNavLink from "src/layouts/components/acl/CanViewNavLink";

// ** Util Import
import { hexToRGBA } from "src/@core/utils/hex-to-rgba";
import {
  Button,
  IconButton,
  Menu,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useState } from "react";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import ExpandLessOutlinedIcon from "@mui/icons-material/ExpandLessOutlined";
import ServiceDropDown from "src/views/pages/Services/ServiceDropDown";
import ITSolutionDropDown from "src/views/pages/ITSolutionDetails/ITSolutionDropDown";

const ListItem = styled(MuiListItem)(({ theme }) => ({
  width: "auto",
  paddingTop: theme.spacing(2.25),
  color: "#544D65",
  fontWeight: 500,
  paddingBottom: theme.spacing(2.25),
  "&:hover": {
    // backgroundColor: theme.palette.action.hover
    color: "#602DE1",
    fontWeight: 700,
  },
  "&.active, &.active:hover": {
    //backgroundColor: hexToRGBA(theme.palette.primary.main, 0.08)
    color: "#602DE1",
    fontWeight: 700,
  },
}));

const HorizontalNavLink = (props) => {
  // ** Props
  const { item, settings, hasParent } = props;

  // ** Hook & Vars
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const { navSubItemIcon, menuTextTruncate } = themeConfig;
  const Wrapper = !hasParent ? List : Fragment;
  const IconTag = item.icon ? item.icon : navSubItemIcon;

  //Getting ID from localstorage for IT Solution Pages
  const itSolutionId = localStorage.getItem("ITSolutionId");

  const handleURLQueries = () => {
    if (Object.keys(searchParams).length && item.path) {
      const arr = Object.keys(searchParams);

      return (
        location.pathname.includes(item.path) &&
        location.pathname.includes(searchParams[arr[0]])
      );
    }
  };

  const isNavLinkActive = () => {
    // if (location.pathname === item.path || handleURLQueries()) {
    //   return true;
    // } else {
    //   return false;
    // }
    const currentPath = location.pathname;
    // Handle Home path (`/`) separately, only active when exactly on `/`
    if (item.path === "/" && currentPath === "/") {
      return true;
    }

    // Check for other paths
    if (item.path !== "/" && currentPath.startsWith(item.path)) {
      return true;
    }

    // Additional condition for handling URL queries, if needed
    if (handleURLQueries()) {
      return true;
    }

    return false;
  };
  //services dropdown
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    event.preventDefault();
    setAnchorEl(null);
  };

  return (
    // <CanViewNavLink navLink={item}>
    <Wrapper
    // {...(!hasParent
    //   ? {
    //       component: 'div',
    //       sx: { borderBottom: 2, borderColor: 'common.white' }
    //     }
    //   : {})}
    >
      {/* <Link to={`${item.path}`}> */}
      <ListItem
        to={
          [
            "Overview",
            "Features",
            "Demo",
            "Resources",
            "Get Quote",
            "Support",
          ].includes(item.title)
            ? `${item.path}/${itSolutionId}`
            : `${item.path}`
        }
        component={Link}
        disabled={item.disabled}
        className={clsx({ active: isNavLinkActive() })}
        target={item.openInNewTab ? "_blank" : undefined}
        onClick={(e) => {
          if (item.path === undefined) {
            e.preventDefault();
            e.stopPropagation();
          }
        }}
        sx={{
          ...(item.disabled
            ? { pointerEvents: "none" }
            : { cursor: "pointer" }),
          ...(!hasParent
            ? {
                height: "45px",
                width: "100%",
                px: 5.5,
                borderRadius: "10px",
                "&.active, &.active:hover": {
                  // boxShadow: 3,
                  backgroundColor: "",
                  "& .MuiButton-root, & .MuiListItemIcon-root": {
                    color: "common.white",
                  },
                },
              }
            : { px: 5 }),
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box>
            {item.title === "IT Solutions" ? (
              <Box>
                <Button
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                  disableRipple
                  disableElevation
                  sx={{
                    "&:hover": {
                      backgroundColor: "transparent",
                      color: "#602DE1",
                      fontWeight: 700,
                    },
                    p: 0,
                    color: "#544D65",
                    fontSize: "16px",
                    fontWeight: 500,
                  }}
                >
                  IT Solutions
                </Button>
                <Menu
                  id="basic-menu"
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem
                    sx={{
                      "&:hover": {
                        backgroundColor: "transparent",
                        cursor: "auto",
                      },
                    }}
                    disableTouchRipple
                  >
                    <ITSolutionDropDown />
                  </MenuItem>
                </Menu>
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  ...(menuTextTruncate && { overflow: "hidden" }),
                }}
              >
                {/* <Button variant="text" sx={{ fontWeight: 600, lineHeight: '21px' }}> */}
                <Translations text={item.title} />
                {/* </Button> */}
              </Box>
            )}
          </Box>
          {/* <Box
            sx={{
              display: "flex",
              alignItems: "center",
              ...(menuTextTruncate && { overflow: "hidden" }),
            }}
          >
            <Translations text={item.title} />
          </Box> */}
          {item.title === "Services" && (
            <Box>
              {open ? (
                <IconButton
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                  sx={{ p: 0, ml: 1, mb: 0.5 }}
                >
                  <ExpandLessOutlinedIcon
                    sx={{ fontSize: "30px", color: "#544D65" }}
                  />
                </IconButton>
              ) : (
                <IconButton
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                  sx={{ p: 0, ml: 1, mb: 0.5 }}
                >
                  <ExpandMoreOutlinedIcon
                    sx={{ fontSize: "30px", color: "#544D65" }}
                  />
                </IconButton>
              )}

              <Menu
                id="basic-menu"
                // anchorOrigin={{
                //   vertical: "bottom",
                //   horizontal: "center",
                // }}
                // transformOrigin={{
                //   vertical: "top",
                //   horizontal: "center",
                // }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem
                  onClick={handleClose}
                  sx={{
                    "&:hover": {
                      backgroundColor: "transparent", // Set hover effect to transparent
                    },
                  }}
                >
                  <ServiceDropDown />
                </MenuItem>
              </Menu>
            </Box>
          )}
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            ...(menuTextTruncate && { overflow: "hidden" }),
          }}
        >
          {/* <ListItemIcon sx={{ color: "text.primary", mr: !hasParent ? 2 : 3 }}>
            <UserIcon
              icon={IconTag}
              componentType="horizontal-menu"
              iconProps={{
                sx:
                  IconTag === navSubItemIcon
                    ? { fontSize: "0.875rem" }
                    : { fontSize: "1.375rem" },
              }}
            />
          </ListItemIcon> */}
        </Box>
      </ListItem>

      {/* </Link> */}
    </Wrapper>
    // </CanViewNavLink>
  );
};

export default HorizontalNavLink;
