// ** MUI Import
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Logo from "../logo";
import logo from "../../../assets/images/logo.svg";

const FallbackSpinner = () => {
  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      {/* <Logo /> */}
      <img src={logo} />
      <CircularProgress disableShrink sx={{ mt: 6 }} />
    </Box>
  );
};

export default FallbackSpinner;
