import React, { Suspense } from "react";
import { Routes, Route, Outlet, Navigate, useLocation } from "react-router-dom";
import FallbackSpinner from "./@core/components/spinner";
import UserLayout from "./layouts/UserLayout";
import BlankLayout from "./@core/layouts/BlankLayout";
import "../src/App.css";
import SubUserLayout from "./layouts/subUserLayout";

const HomePage = React.lazy(() => import("./pages/home"));
const ServicesPage = React.lazy(() => import("./pages/Services"));
const ServicesDetailPage = React.lazy(() => import("./pages/ServicesDetail"));
const ITSolutionOverviewPage = React.lazy(() =>
  import("./pages/ITSolutionDetails/ITSolutionOverview")
);
const ITSolutionFeaturesPage = React.lazy(() =>
  import("./pages/ITSolutionDetails/ITSolutionFeatures")
);
const ITSolutionDemoPage = React.lazy(() =>
  import("./pages/ITSolutionDetails/ITSolutionDemo")
);
const ITSolutionResourcesPage = React.lazy(() =>
  import("./pages/ITSolutionDetails/ITSolutionResources")
);
const ITSolutionGetQuotePage = React.lazy(() =>
  import("./pages/ITSolutionDetails/ITSolutionGetQuote")
);
const ITSolutionSupportUsPage = React.lazy(() =>
  import("./pages/ITSolutionDetails/ITSolutionSupport")
);
const CustomerExperiencePage = React.lazy(() =>
  import("./pages/CustomerExperience")
);
const CareersPage = React.lazy(() => import("./pages/Careers"));
const CareerDetailsPage = React.lazy(() => import("./pages/CareerDetails"));
const ContactUsPage = React.lazy(() => import("./pages/ContactUs"));
const ClientsPage = React.lazy(() => import("./pages/Resources/Clients"));
const BlogsPage = React.lazy(() => import("./pages/Resources/Blogs"));
const BlogDetailsPage = React.lazy(() =>
  import("./pages/Resources/BlogDetails")
);
const CaseStudyPage = React.lazy(() => import("./pages/Resources/CaseStudy"));
const AboutUsPage = React.lazy(() => import("./pages/AboutUs"));

const TermsAndConditionPage = React.lazy(() =>
  import("./pages/TermsAndCondition")
);
const PrivacyPolicyPage = React.lazy(() => import("./pages/PrivacyPolicy"));
const FAQsPage = React.lazy(() => import("./pages/FAQs"));
const Page401 = React.lazy(() => import("./pages/401"));
const Page404 = React.lazy(() => import("./pages/404"));

function App() {
  return (
    <Suspense fallback={<FallbackSpinner />}>
      <Routes>
        <Route
          element={
            <BlankLayout>
              <Outlet />
            </BlankLayout>
          }
        >
          <Route path="/401" element={<Page401 />} />
          <Route path="/404" element={<Page404 />} />
        </Route>

        <Route
          element={
            <UserLayout>
              <Outlet />
            </UserLayout>
          }
        >
          <Route path="/" element={<HomePage />} />

          <Route path="/services" element={<ServicesPage />} />

          <Route path="/services-detail" element={<ServicesDetailPage />} />

          <Route
            path="/customer-experience"
            element={<CustomerExperiencePage />}
          />
          <Route path="/careers" element={<CareersPage />} />
          <Route path="/career-details/:id" element={<CareerDetailsPage />} />
          <Route path="/about-us" element={<AboutUsPage />} />
          <Route path="/contact-us" element={<ContactUsPage />} />
          <Route path="/clients" element={<ClientsPage />} />
          <Route path="/blogs" element={<BlogsPage />} />
          <Route path="/blog-details/:id" element={<BlogDetailsPage />} />
          <Route path="/caseStudy" element={<CaseStudyPage />} />
          <Route
            path="/terms-and-conditions"
            element={<TermsAndConditionPage />}
          />
          <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
          <Route path="/faqs" element={<FAQsPage />} />
        </Route>
        <Route
          element={
            <SubUserLayout>
              <Outlet />
            </SubUserLayout>
          }
        >
          <Route
            path="/it-solution-overview/:id"
            element={<ITSolutionOverviewPage />}
          />
          <Route
            path="/it-solution-features/:id"
            element={<ITSolutionFeaturesPage />}
          />
          <Route
            path="/it-solution-demo/:id"
            element={<ITSolutionDemoPage />}
          />
          <Route
            path="/it-solution-resources/:id"
            element={<ITSolutionResourcesPage />}
          />
          <Route
            path="/it-solution-getQuote/:id"
            element={<ITSolutionGetQuotePage />}
          />
          <Route
            path="/it-solution-supportUs/:id"
            element={<ITSolutionSupportUsPage />}
          />
        </Route>
        <Route path="*" element={<Navigate to="/404" replace />} />
      </Routes>
    </Suspense>
  );
}

export default App;
