// ** Icon imports

const subNavigation = () => {
  return [
    {
      title: "IT Solutions",
    },
    {
      title: "Overview",
      path: "/it-solution-overview",
    },
    {
      title: "Features",
      path: "/it-solution-features",
    },
    {
      title: "Demo",
      path: "/it-solution-demo",
    },
    {
      title: "Resources",
      path: "/it-solution-resources",
    },
    {
      title: "Get Quote",
      path: "/it-solution-getQuote",
    },
    {
      title: "Support",
      path: "/it-solution-supportUs",
    },
  ];
};

export default subNavigation;
