import React from "react";
import Fab from "@mui/material/Fab";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import msgIcon from "../../assets/images/message-2.png";

function TabComponent() {
  return (
    <Box sx={{ "& > :not(style)": { m: 0 } }}>
      <Link to="/" style={{ textDecoration: "none" }}>
        <Fab
          color="primary"
          size="large"
          aria-label="add"
          style={{
            position: "fixed",
            bottom: "50px",
            right: "24px",
            backgroundColor: "#602DE1",
          }}
        >
          {/* <WhatsAppIcon /> */}
          <img src={msgIcon} alt="" />
        </Fab>
      </Link>
    </Box>
  );
}

export default TabComponent;
